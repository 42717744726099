import type { WritableComputedOptions } from '@vue/reactivity'

/**
 * Create reactive state for SWR
 * On server side the data will be fetched eagerly
 */
export async function reactiveLoad<T>(
  get: () => T | undefined,
  set: (data: T) => void,
  fetch: () => Promise<T>,
  init?: T,
) {
  const data = computed({ get, set } as WritableComputedOptions<T>)
  const loading = ref(false)

  if (data.value == null) {
    if (init != null)
      data.value = init

    const task = async () => {
      try {
        loading.value = true
        const fetched = await fetch()
        if (data.value != null)
          data.value = Object.assign(data.value, fetched)
        else
          data.value = fetched
      } catch (err) {
        console.error(err)
        data.value = undefined as any
      } finally {
        loading.value = false
      }
    }

    if (process.client) {
      task()
    } else {
      // await task()
      // await task()
    }
  }

  return reactive({ loading, data })
}
